/*--------------------------------------------------
Generic CSS for initial components
--------------------------------------------------*/

:root {
  --site-background: #FFF;
  --text-color: #000;

  --button-color: #D9D9D9;
  --button-text-color: #000000;
  --button-hover-color: rgb(156, 156, 156);
  --border-button-text-color: #000000;

  --container-text-color: #000000;
  --container-color: #FFF;
  --border-container-text-color: #000000;
  --border-container-color: #FFF;

  --link-color: #172236;

  --white: #FFFFFF;
  --black: #000000;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  background-color: var(--site-background);
  color: var(--text-color);
  height: 100%;
  min-height: 100vh;
}

.main-container {
  width: 75%;
  margin: auto;
  background-color: transparent;
}

.main-container .container {
  text-align: center;
  margin: 1em auto;
  width: 100%;
  padding: 1em;
  border-radius: 10px;
  background-color: var(--container-color);
  color: var(--container-text-color);
}

.main-container .border-container {
  text-align: center;
  width: 100%;
  margin: 1em auto;
  padding: 1em;
  border-radius: 10px;
  border: solid 3px var(--border-container-color);
  color: var(--border-container-text-color);
}

.main-container button {
  cursor: pointer;
  border: none;
  background-color: var(--button-color);
  display: flex;
  width: 200px;
  height: 50px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0; 
  color: #000;
  text-align: center;
  font-family: Bungee, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal; 
}

.main-container button:hover {
  background-color: var(--button-hover-color);
}

.main-container .border-button {
  cursor: pointer;
  padding: .35em .5em;
  font-size: 16px;
  background-color: transparent;
  border: solid 3px var(--button-color);
  color: var(--border-button-text-color);
  border-radius: 10px;
  font-weight: 600;
  margin: .5em;
}
.main-container .border-button:hover {
  background-color: var(--button-hover-color);
}

.main-container a {
  color: var(--link-color);
  text-decoration: none;
}

.main-container a:hover {
  text-decoration: underline;
}

.main-container input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: block;
}

.main-container .center {
  justify-content: center;
  margin: auto;
  text-align: center;
}

.main-container .flex-container {
  display: flex;
  flex-wrap: wrap;
}


.loading-container {
  position: fixed; 
  top: 0;
  left: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: #47474741;
}

.loading-text {
  font-size: 24px;
  font-weight: bold;
  color: #FFF; 
  text-shadow: 4px 2px 6px black;
}


